.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header.MuiPaper-root {
  background-color: #ddd7d7;
  color: #35423b;
  font-family: "PT Mono", monospace;
}

div.MuiToolbar-root {
  min-width: 375px;
  background-color: #ddd7d7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

h6.MuiTypography-root {
  font-family: "PT Mono", monospace;
  font-size: 25px;
}

span.MuiButton-label {
  font-family: "PT Mono", monospace;
}

div.MuiPaper-root {
  right: 30px;
}

div.menu {
  background-color: none !important;
}

div.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
  position: relative;
  right: 40px;
}

li.MuiButtonBase-root {
  font-family: "PT Mono", monospace;
  color: #35423b;
}

#menuLogo {
  height: 40px;
}

#menuLogo:hover {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@media (max-width: 750px) {
  div.MuiToolbar-root {
    flex-direction: column;
    justify-content: center;
  }
}

.draw {
  stroke: black;
  stroke-width: 2px;
  stroke-dashoffset: -2000;
  stroke-dasharray: 2000;
  animation-name: draw;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-direction: forwards;
  animation-timing-function: normal;
  animation-fill-mode: forwards;
}

@keyframes draw {
  0% {
    stroke-dashoffset: -2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.fadeIn {
  animation-name: fade;
  animation-duration: 4.3s;
  animation-iteration-count: 1;
}

.fadeInSmall {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.hidden {
  visibility: hidden;
}

.shrink {
  animation-name: shrink;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

@keyframes shrink {
  0% {
    transform: scale(1.9);
    position: relative;
    top: 200px;
    stroke: cyan;
    fill: #00ffbf;
  }
  5% {
    stroke: cyan;
  }
  100% {
    transform: scale(1);
    position: relative;
    top: 0;
    stroke: black;
  }
}
.sway {
  animation-name: sway;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

@keyframes sway {
  20% {
    transform: skew(1deg, 0deg);
    stroke: cyan;
    fill: #00ffbf;
  }
  50% {
    transform: skew(-1deg, 0deg);
  }
  75% {
    transform: skew(1deg, 0deg);
  }
  100% {
    transform: skew(0deg, 0deg);
    stroke: black;
  }
}
.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #35423b;
  background-color: #f0ebd8;
}

.projects div.fadeAway {
  opacity: 0.3;
}

.projects h1 {
  padding: 15px 15px 0px 15px;
  margin-bottom: 0px;
  font-size: 25px;
  font-family: "PT Mono", monospace;
}

.projects h4 {
  color: black;
  margin: 8px 0 0 15px;
  font-family: "PT Mono", monospace;
}

.projects p {
  color: black;
  margin: 30px 10px 10px 10px;
  font-size: 12px;
  line-height: 100%;
  font-family: "PT Mono", monospace;
}

.projects a:hover {
  text-decoration: none;
}

.projects div.project_tiles {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 30px;
}

.projects div.project_tiles div.card {
  height: 100px;
  width: 40vw;
  padding: 5px;
  margin: 15px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #ffffff;
}

.projects div.image {
  display: flex;
  justify-content: center;
}

.projects .needs-padding {
  padding-left: 50px;
}

.projects div.project_tiles div.card div.description {
  height: 100px;
}

.grow {
  animation-name: grow;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  transform: scale(1.1);
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.normalize {
  animation-name: normalize;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

@keyframes normalize {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.about {
  display: flex;
  flex-direction: column;
  color: #35423b;
}

.about h1 {
  padding: 15px 15px 0px 15px;
  margin-bottom: 0px;
  font-family: "PT Mono", monospace;
  font-size: 25px;
}

.about div {
  margin: 30px 50px 50px 50px;
}

.about p {
  margin: 16px;
  font-family: "PT Mono", monospace;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #35423b;
  background-color: #f0ebd8;
  font-family: "PT Mono", monospace;
  min-height: 425px;
}

.contact a:hover {
  color: #176b6b !important;
  text-decoration: none;
}

.contact .contact_div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contact h1 {
  padding: 15px;
  font-size: 25px;
}

.contact h3 {
  padding: 8px 0 8px 0;
  font-size: 25px;
}

.contact .contact_info {
  width: 40vw;
  height: 250px;
  min-width: 290px;
  padding: 15px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 40px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
}

.contact .contact_info p {
  margin: 8px;
  color: black;
}

.contact a {
  color: black;
  text-decoration: none;
}

.contact iframe {
  position: relative;
  top: 30px;
  height: 500px;
  width: 70vw;
  min-width: 290px;
  margin-bottom: 150px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(99%);
  /* Chrome 19+ & Safari 6+ */
  -webkit-backface-visibility: hidden;
  /* Fix for transition flickering */
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  overflow: hidden;
  z-index: -1;
}

.footer .right {
  background-color: #ddd7d7;
  clip-path: polygon(100% 0%, 0 100%, 100% 100%);
  width: 100%;
  z-index: 1;
}

.footer img {
  position: relative;
  left: 70%;
  bottom: 15px;
  height: 190px;
  opacity: 0.3;
  z-index: 2;
}

html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
  width: 100% !important;
}

div.svg_container {
  width: 100%;
}

.selected-poly polyline,
.selected-poly polygon {
  fill: #87c8b8 !important;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.flex-right {
  justify-content: flex-end;
}

.flex-left {
  justify-contact: flex-start;
}

.width-50 {
  width: 50vw;
}

.width-75 {
  width: 75vw;
}

.width-95 {
  width: 95vw;
}

.width-100 {
  width: 100vw;
  min-width: 1050px;
}

.height-85 {
  height: 85vh;
}

.bottom-125 {
  position: relative;
  bottom: 125px;
}

.top-2rel {
  position: relative;
  top: 2.3vh;
}

.right-50 {
  position: relative;
  top: 5px;
  right: 50px;
}

.py-10 {
  padding: 0px 10px;
}

.pl-0 {
  padding-left: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-50 {
  margin-bottom: 50px;
}

.text-left {
  text-align: left;
}

html,
.App {
  background-color: #f0ebd8;
}

body {
  min-width: 900px;
}

a {
  text-decoration: none !important;
  color: #264653;
}

.nav-logo {
  height: 50px;
  padding: 10px 0px 10px 10px;
  margin-right: -15px;
}
